const PROJECT_DETAIL_FINISHED_MOUNTED = "projectDetailFinishedMounted";
const YOUTUBE_IFRAME_API_READY = "onYouTubeIframeAPIReady";
const VIEW_TRANSITION_START = "viewTransitionStart";
// const VIEW_TRANSITION_END = "viewTransitionEnd";
const VIEW_TRANSITION_IN_COMPLETE = "viewTransitionInComplete";

const DEFAULT_META_PAGE_DESCRIPTION = "Portfolio of James Safechuck. VP of Technology and Innovation";

export {
    PROJECT_DETAIL_FINISHED_MOUNTED,
    YOUTUBE_IFRAME_API_READY,
    // VIEW_TRANSITION_END,
    VIEW_TRANSITION_START,
    VIEW_TRANSITION_IN_COMPLETE,
    DEFAULT_META_PAGE_DESCRIPTION,
}