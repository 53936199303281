// import DeviceDetector from 'device-detector-js'

// const deviceDetector = new DeviceDetector();

// const userAgent = navigator.userAgent;

// const device = deviceDetector.parse(userAgent);

// export {device}

import UAParser from "ua-parser-js";
const parser = new UAParser();
const device = parser.getResult();

export {device};