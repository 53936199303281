<template>
    <article class="project-article" @click="handleItemClick" :item-id="`${itemId}`">
            <div ref="mainImageContainer" class="project-article__img">
                <img class="lazyload" :data-src="`${heroImage.hires}`"
                
                :data-lowsrc="`${heroImage.lowres}`"
                />
            </div>
            <div class="project-article__details">
                    <div class="project-article__bg"></div>
                <div class="project-article__detail-text">
                    <div
                     class="project-article__name font-weight-large">{{name}}</div>
                    <div class="project-article__subname font-wieght-normal">{{subname}}</div>
                </div>
            </div>
            <!-- <div class="project-article__border"></div> -->
        </article>
</template>

<script>
export default {
    name:"ProjectNavItem",
    emits:["onClick"],
    props:{
        name:String,
        subname:String,
        itemId:String,
        imageSize:{
            w:Number,
            h:Number,
        },
        heroImage:{
            hires:String,
            lowres:String,
        },
    },
    setup() {
        
    },
    methods:{
        handleItemClick:function(event){
        // console.log(event.target.attributes["item-id"].value);
            // console.log(event.target);
            const itemId = event.currentTarget.attributes["item-id"].value;
            let image = this.$refs.mainImageContainer.querySelector("img");
            // console.log("image from component ",image);
            this.$emit("onClick",event,itemId,image);
            
            // console.log(itemId)
        },
    }
}
</script>

<style lang="scss">
@import '@/scss/_global.scss';

    $subnameColor:#6a8687;
    $titleBackgroundColor:$backgroundColorLight;
    $articleNameColor:#415f63;
    $titleBoxShadowColor:rgb(255, 255, 255);
    
    $articleNameColor:$textColorMain;
    $subnameColor:$textColorSub;

    @keyframes image-hover-effect {
        from {
            filter:brightness(1.0);
        }

        30% {
            filter:brightness(2.0);
        }

        to{
            filter:brightness(1.0);
        }
    }


    .project-article{ 
        height:0px;
        width:100%;
        position:relative;
        padding-top: 56.35%;
        transition:box-shadow;
        transition-duration: 200ms;
        transition-timing-function: ease-in-out;
        // box-shadow:inset 0px 0px 0px 1px $titleBoxShadowColor;

        img{
            pointer-events: none;
        }

        @media(hover:hover) and (pointer:fine){

            &:hover{ 
                // box-shadow:inset 0px 0px 0px 10px $titleBoxShadowColor;
                cursor:pointer;
                img{ 
                    transform:scale(1.2);
                    animation:image-hover-effect 0.5s;
                    animation-fill-mode: forwards;
                    animation-timing-function: ease-in-out;
                }

                // .project-article__details {
                //     // bottom:10px;
                //     .project-article__bg{
                //         opacity: 100%;
                //     }
                // }

                // .project-article__border {
                //     box-shadow:inset 0px 0px 0px 10px $titleBoxShadowColor;
                // }
                
            }

        }
    }

    // .project-article__border {
    //     position:absolute;
    //     top:0px;
    //     left:0px;
    //     width:100%;
    //     height: 100%;
    //     transition:box-shadow;
    //     transition-duration: 200ms;
    //     transition-timing-function: ease-in-out;
    //     box-shadow:inset 0px 0px 0px 1px $titleBoxShadowColor;
    //     opacity:80%;
    //     z-index: 1;
    // }

    .project-article__img {
        width:100%;
        height:100%;
        position:absolute;
        top:0px;
        left:0px;
        background-color:rgb(109, 109, 109);
        overflow:hidden;
        pointer-events: none;

        img{

            position:absolute;
            top:0px;
            left:0px;
            width:100%;
            transition:transform 500ms;
            transition-timing-function: ease-in-out;
        }

        .ls-blur-up-img {
            filter: blur(10px);
            opacity: 1;
            transition: opacity 1000ms, filter 1500ms;
        }

        .ls-blur-up-img.ls-inview.ls-original-loaded {
            opacity: 0;
            filter: blur(5px);
        }
    }

    .project-article__details {
        position:absolute;
        bottom:0px;
        right:0px;
        // z-index: -1;
        // transition:bottom;
        // transition-duration: 200ms;
        // transition-timing-function: ease-in-out;
        // width:100%;
        // width:70%;
    }

    .project-article__bg{
        position:absolute;
        background:$titleBackgroundColor;
        width:100%;
        height:100%;
        // z-index: -1;
        inset:0;
        // opacity:85%;
        // transition:opacity 300ms;
        // transition-timing-function: ease-in-out;
    }
    .project-article__detail-text{ 
        position:relative;
        right:0px;
        bottom:0px;
        padding-left:1rem;
        padding-right:1rem;
        line-height: 1.0rem;
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: right;
        // min-width: 50%;
        // width:50%;
        
    }

    // .project-article__detail-text::before {
    //     content:"";
    //     background:$titleBackgroundColor;
    //     width:75px;
    //     height:100%;
    //     position:absolute;
    //     top:0px;
    //     left:-2.5rem;
    //     clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    //     z-index: -2;
    // }

    // .project-article__detail-text::after {
    //     content:"";
    //     background:$titleBackgroundColor;
    //     width:100%;
    //     height:100%;
    //     position:absolute;
    //     top:0px;
    //     left:0px;
    //     z-index: -1;
    // }

    // 0.5, 1.2
    .project-article__name {
        // font-weight:700;
        // font-size:clamp(0.8rem,1.2rem,1.3rem);
        // font-size:1rem;
        text-transform: uppercase;
        // margin-bottom: 3px;
        color:$articleNameColor;
        // display: inline-block;
    }

    .project-article__subname {
        // font-weight:500;
        color:$subnameColor;
        text-transform: uppercase;
        font-size:0.9rem;
        // display: inline-block;
    }


    // .project-article{ 
    //     display:grid;
    //     // grid-template-rows:repeat(6,1fr);
    //     grid-template-columns: repeat(auto-fill,1fr);
    //     position:relative;
        
    // }


    // .project-article__img{
    //     grid-row:1/-1;
    //     grid-column:1/-1;
    //     // width:100%;
    // }

    // .project-article__details {
    //    grid-row:-2/-1;
    //    grid-column:-2/-1;
    // //    position:absolute;
    //    background-color:red;
    // }

</style>