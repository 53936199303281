<template>
 <home-main></home-main>
</template>

<script>

import HomeMain from '@/components/HomeMain.vue'

export default {
  name:"HomeView",
  components: {
    HomeMain
  }
}
</script>
