<template>
    <div class="project-sort-view">

        <div class="categories-container">
            <category-select-button
            categoryId="Reset All"
            @click="resetCategories"
            ref="resetButton"
            >Reset All</category-select-button>
            <category-select-button v-for="(item,index) in categories"
            :key="index"
            :categoryId="item"
            @click="handleCategoryClick"
            ref="items"
            >
            {{item}}
            </category-select-button>
        </div>

        <div class="bg"></div>

    </div>
</template>

<script>
// sorting All, Sites, Applications, Mobile, Games, Motion Graphics, VR, AR, Prototype
import store from "@/store/index";
import CategorySelectButton from "@/components/CategorySelectButton.vue"
export default {

    components:{
        CategorySelectButton,
    },
    data(){
        return {
            selectedCategories:(new Set()),
        }
    },  
    setup(){
        const categories = store.getters.categories;

        // console.log(categories);

        return {
            categories,
        }
    },
    methods:{
        clearCategories(){
            this.$refs.resetButton.setActive(false);
            this.$refs.items.forEach((item)=>{
                item.setActive(false);
                this.selectedCategories.clear();
            })
        },
        resetCategories(target,categoryId,active){
            // target.setActive(false);
            // this.$refs.items.forEach((item)=>{
            //     item.setActive(false);
            //     this.selectedCategories.clear();
            // })
            //  console.log(this.selectedCategories);
            this.clearCategories();
        },
        handleCategoryClick(target,categoryId,active){
            // console.log(event,categoryId,active);

            if(active){
                // todo add to list
                if(!this.selectedCategories.has(categoryId)){
                    this.selectedCategories.add(categoryId);
                }
            }else{
                // todo remove from list
                this.selectedCategories.delete(categoryId);
            }

            // console.log(this.selectedCategories);
        },

    },
    
}
</script>

<style lang="scss" scoped>
@import "@/scss/_global.scss";

$backgroundColor:$backgroundColorLight;

@keyframes show-sort {
    from {
        clip-path:circle(10% at 100vw -32px);
    }

    to{
        clip-path:circle(200% at 100vw -32px)
    }
}

.project-sort-view{
    position:fixed;
    top:100px;
    left:0px;
    width:100%;
    height:100vh;
    display:flex;
    flex-direction: column;
}

.project-sort-view.active {
    animation:show-sort .75s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

.categories-container {
    display:flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    top:42px;
}

.bg {
    position:absolute;
    width:100%;
    height:100%;
    // opacity:0.95;
    background-color:$backgroundColor;
    z-index: -1;
    top:0px;
    left:0px;
    // backdrop-filter: blur(5px);
}

</style>