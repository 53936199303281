
// import {gsap} from "@/utils/gsap-core";
// import {ScrollTrigger} from "@/utils/ScrollTrigger";
import {ScrollSmoother} from "@/utils/ScrollSmoother";

const ScrollerMixin = {
    mounted:function(){
        // console.log("scroller mixin mounted");

        const smoothScroller = ScrollSmoother.get() 
        if(smoothScroller){
            smoothScroller.scrollTop(0);
        }
        
        // scroll smooth wasn't recognizing new page content
        setTimeout(()=>{
            // console.log("mixin delay");
            const smoothScroller = ScrollSmoother.create({
                smooth: 2,               // how long (in seconds) it takes to "catch up" to the native scroll position
                effects: true,           // looks for data-speed and data-lag attributes on elements
                // smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
                normalizeScroll:true,
              });
            // smoothScroller.scrollTrigger.refresh();

        },250)
    }
}

export default ScrollerMixin;