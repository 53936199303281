import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import emitter from "@/utils/Emitter"
import wrapDirective from "@/directives/LetterWrap"
import VueGTag from "vue-gtag"

import {
    YOUTUBE_IFRAME_API_READY
  } from "@/utils/consts";

import 'lazysizes'
import 'lazysizes/plugins/blur-up/ls.blur-up'

import {device} from "@/utils/device"
// console.log(device);

// console.log(store.getters.loaded);

const app = createApp(App)

wrapDirective(app);

app.use(store).use(router);
app.use(VueGTag,{
    // pageTrackerUseFullPath:true,
    config:{
        id:"G-1LDGYMS2RP"
    }
},router);

store.dispatch("loadSiteData").then((result)=>{
    // console.log(result);
    siteDataLoadComplete();
});

function siteDataLoadComplete(){
    
    app.mount('#app');
}

// load youtube player api
let tag = document.createElement("script");
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName("script")[0];
firstScriptTag.parentNode.insertBefore(tag,firstScriptTag);


window.onYouTubeIframeAPIReady = () =>{
    // console.log("on youtube player api ready");
    // console.log(window.YT);
    store.commit("setYTAPI",window.YT);
    emitter.emit(YOUTUBE_IFRAME_API_READY);
    // console.log(app);
    // app.provide("YT",window.YT);
}

if(device?.device?.type){
    document.body.classList.add(device.device.type);
}