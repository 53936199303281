import { createStore } from 'vuex'
// import {projectData} from "./projectData";

import {SET_SITE_DATA,
  SITE_DATA_NOT_LOADED,
  SITE_DATA_LOADED} from "./types";


const SITE_DATA_API_URL = process.env.VUE_APP_SITE_DATA_API_URL;//"https://e8interactive.com/e8-api/wp-json/e8/v1/all";

export default createStore({
  state: {
    loaded:SITE_DATA_NOT_LOADED,
    siteData:null,
    projectRouteDidComeFromHome:false,
    homeTransitionImage:null,
    YTAPI:null,
    _cachedProjectsByCategory:[],// dictionary of saved searches
  },
  getters: {
    loaded(state){
      return state.loaded;
    },
    projectRouteDidComeFromHome(state){
        return state.projectRouteDidComeFromHome;
    },
    projects(state){
        return state.siteData.projects;
    },
    socialLinks(state){
      return state.siteData.about.socialLinks;
    },
    aboutData(state){
      return state.siteData.about;
    },
    getProjectById:(state) => (id) => {
        return state.siteData.projects.find(project => project.projectId === id);
    },
    getHomeTransitionImage(state){
        return state.homeTransitionImage;
    },
    YTAPI(state){
        return state.YTAPI;
    },
    categories(state){
      return state.siteData.categories;
    },
    getRelatedProjectsByCategories:(state) =>(categories,projectId)=>{
        let searchResults = new Set();

        if(!projectId){
          projectId = "-1";
        }

        categories.forEach((category)=>{

          // first check if we have cached results
          const cachedData = state._cachedProjectsByCategory[category];
          let result = null;
          if(cachedData!==undefined){
            // console.log("we have this category query cached, will use that");
            result = cachedData;
          }else{
            // console.log("no category query cache, gonna search");
            result = state.siteData.projects.filter((project)=>{
              return project.categories.some((item)=>item.toLowerCase()===category.toLowerCase())
            });

            // save for next time we query;
            state._cachedProjectsByCategory[category] = result;
          }

          // make sure we don't have any duplicate projects, this happens because we have multiple categories
          result.forEach((result)=>{
            // console.log(result.projectId,projectId);
            if(result.projectId !== projectId && !searchResults.has(result)){
              searchResults.add(result);
            }
          })
        });

        return Array.from(searchResults.values());
    },
  },
  mutations: {
      setProjectRouteDidComeFromHome(state,value){
        state.projectRouteDidComeFromHome = value;
      },
      setHomeTrasitionImage(state,image){
        state.homeTransitionImage = image;
      },
      [SET_SITE_DATA](state, payload){
        // todo
        // console.log("set site data ", payload);
        state.loaded = SITE_DATA_LOADED;
        state.siteData = payload;
        
      },
      setYTAPI(state,value){
        state.YTAPI = value;
      },
  },
  actions: {
      loadSiteData(context){

        // console.log("loadSiteData()");

        return new Promise( (resolve,reject) =>{
          let cache_bust = new Date().getTime();
          // let path = process.env.BASE_URL+"data/siteData.json?cache_bust="+cache_bust;
          let path = SITE_DATA_API_URL;
          // console.log(path);
          fetch(path).then(async (response)=>{
            if(!response.ok){
              // console.log("error getting site data");
              reject("error with getting site data")
            }else {
              let data = await response.json();
              // console.log(data);
              context.commit(SET_SITE_DATA,data);
              // console.log("Finished Loading Site Data");
              resolve("Finished Loading Site Data");
            }
          });
        });
      },
  },
  modules: {
  }
})
