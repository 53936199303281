<template>
  <HeaderVue ref="header"></HeaderVue>
  <loading-overlay :class="{hide:!isLoadingProjectImage}"></loading-overlay>
  <div id="sectionTransitionContainer" ref="sectionTransitionContainer"></div>
  <div id="smooth-wrapper">
    <div id="smooth-content">
      <router-view @onProjectThumbClick="handleProjectThumbClick" v-slot="{Component}">
      <!-- <transition 
      name="slide"
      > -->
      <transition 
      @enter="handleEnter"
      @leave="handleLeave"
      @after-enter="onAfterEnter"
      :css="false"
      appear
      >
          <component 
          :is="Component" 
          :key="$route.path">
          </component>
      </transition>
      </router-view>
    </div><!--smooth-content-->
  </div><!--smooth-wrapper-->
</template>

<script>
// import {gsap} from "gsap"
import {gsap} from "@/utils/gsap-core";
import {CSSPlugin} from "@/utils/CSSPlugin";
import { ScrollToPlugin } from "@/utils/ScrollToPlugin";
import {ScrollTrigger} from "@/utils/ScrollTrigger";
import {ScrollSmoother} from "@/utils/ScrollSmoother";
import {Flip} from "@/utils/Flip";
import emitter from "@/utils/Emitter";
import HeaderVue from "./components/Header.vue";
import store from "@/store/index";
import { loadImage } from "./utils/utils";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import {PROJECT_DETAIL_FINISHED_MOUNTED, VIEW_TRANSITION_START, VIEW_TRANSITION_IN_COMPLETE} from "@/utils/consts";

export default {
  components:{ 
    HeaderVue,
    LoadingOverlay
  },
  setup() {
    gsap.registerPlugin(CSSPlugin,ScrollToPlugin,ScrollTrigger,ScrollSmoother,Flip);

    const docStyle = getComputedStyle(document.documentElement);

    const headerHeight = parseInt(docStyle.getPropertyValue("--header-height"));

    return {
      headerHeight,
    }

  },
  data(){
    return{
      isTransitioning:false,
      isLoadingProjectImage:false,
    }
  },
  methods:{
    // handleBeforeEnter(el){
    //   // console.log("handleBeforeEnter ",el);
    // },
    async handleEnter(el,done){
      // console.log("handleEnter ",el);
      // console.log("handle enter");

      this.$refs.header.showLoading();
      /* Note: this is the page transition for each page. Feeling unnecessary at the moment
      // transition from bottom up
      await gsap.to(el,{
          y:window.innerHeight,
          duration:0
        })

      await gsap.to(el,{
          y:0,
          ease:"power2.inOut",
          duration:1.0
        })
        // transition from bottom up
        */
      // alpha transition
        await gsap.to(el,{
          opacity:0.2,
          y:(window.innerHeight * 0.15),
          duration:0
        })

      await gsap.to(el,{
          opacity:1,
          y:0,
          ease:"power2.inOut",
          duration:0.35
        })
        // alpha transition

        done();
      
    },
    // handleBeforeLeave(el){
    //   // console.log("handleBeforeLeave ",el);
    // },
    onAfterEnter(el){
      // console.log("onAfterEnter");

      // console.log("emit => VIEW_TRANSITION_IN_COMPLETE");
      this.$refs.header.hideLoading();
      emitter.emit(VIEW_TRANSITION_IN_COMPLETE);
    },
    handleLeave(el,done){
      // console.log("handleLeave ",el);
      done();
    },
    transitionToProjectDetail:function(target,id,image){
      
      // this.$refs.header.hideHamburger();
      requestAnimationFrame(()=>{

        emitter.emit(VIEW_TRANSITION_START);

          const itemRect = target.getBoundingClientRect(target);
          // console.log(id);
          // console.log(itemRect);
          // console.log(image.naturalWidth);
          const transitionContainer = this.$refs.sectionTransitionContainer;

          // const transitionImage = new Image(itemRect.width,itemRect.height);
          const transitionImage = document.createElement("canvas");
          transitionImage.width = image.naturalWidth;
          transitionImage.height = image.naturalHeight;
          const context = transitionImage.getContext("2d");
          // context.drawImage(image,0,0,itemRect.width,itemRect.height);
          context.drawImage(image,0,0,image.naturalWidth,image.naturalHeight);

          // todo: get source from store item
          // transitionImage.src = `https://picsum.photos/seed/${id}/1920/1080`;
          transitionImage.style.position = "absolute";
          transitionImage.style.width = itemRect.width+"px";
          transitionImage.style.height = itemRect.height+"px";

          let headerHeight = this.headerHeight;

          const topOffset = (itemRect.top-headerHeight)+"px";
          const leftOffset = itemRect.left+"px";
          // need to subtract the header offset
          transitionImage.style.top = "0px";
          transitionImage.style.left = "0px";
          transitionImage.style.transformOrigin = "transform-origin: top left";
          transitionImage.style.transform = `translate(${leftOffset},${topOffset})`;

          transitionContainer.appendChild(transitionImage);

          // save temp reference for later removal
          this.tempTransitionImage = transitionImage;
         

          // const imageRatio = itemRect.width/itemRect.height;
          const articleContainer = document.querySelector(".projects-container");
          const containerStyle = window.getComputedStyle(articleContainer);
          // console.log(containerStyle);

          const containerWidth = parseFloat(containerStyle.getPropertyValue("width"));
          const containerPadding = parseFloat(containerStyle.getPropertyValue("padding-left"));
          // console.log(containerWidth - (containerPadding*2));
          const windowWidth = containerWidth - (containerPadding*2);
          // console.log(containerWidth);

          const imageToWindow = windowWidth/itemRect.width;
          // console.log(imageToWindow*itemRect.width);

          // console.log(itemRect.width, (itemRect.width*imageToWindow))
          // window.scrollTo(0,0);
          //TODO: BUG, when image isn't fully loaded we don't get reference to image because of the lazy loading mechanism
          store.commit("setHomeTrasitionImage",transitionImage);
          
          gsap.to(window, {
            duration:0.85,
            scrollTo:{
              x:0,
              y:0
            },
            ease:"power2.inOut"
          });

          //TODO: Note: prob should pass ref to function in emitter.off
          emitter.on(PROJECT_DETAIL_FINISHED_MOUNTED,this.handleProjectDetailFinishedMount);

          gsap.to(transitionImage, {
            duration:0.85,
            scaleX:imageToWindow,
            scaleY:imageToWindow,
            translateX:0,// Note: magic number was 20 to take into consideration padding, if we add padding back to container we should set this variable somewhere in root and use that
            translateY:0,
            ease:"power2.inOut"
          }).then(()=>{
            
            this.$router.push({
              name:"project",
              params:{
                projectId:id,
              }
            });
          })
      })

    },
    handleProjectDetailFinishedMount(){
      emitter.off(PROJECT_DETAIL_FINISHED_MOUNTED,this.handleProjectDetailFinishedMount);

      const transitionImage = this.tempTransitionImage;
      const transitionContainer = this.$refs.sectionTransitionContainer;

      // console.log(transitionImage);

      gsap.to(transitionImage,{
      opacity:0,
      ease:"power2.inOut",
      duration:0.25
      }).then(()=>{
        
        transitionContainer.removeChild(transitionImage);
        this.tempTransitionImage = null;
        this.isTransitioning = false;
      })
    },
    handleProjectThumbClick:function(event,id,image){
      // console.log("app handle thumb click", event, id)
      // console.log(event.target);
      if(this.isTransitioning){
        return;
      }

      this.isTransitioning = true;

      this.$refs.header.showLoading();

      let isLazyLoading = image.classList.contains("lazyloading")
      if(isLazyLoading){
        this.isLoadingProjectImage = true;
        // console.log("we are in the middle of lazy loading!!!! fetch large image before we transition");
        const url  = image.getAttribute("data-src");
        // console.log(url);
        loadImage(url).then((image)=>{
          // console.log("App - done loading lazy image ",image);
          this.isLoadingProjectImage = false;
          this.transitionToProjectDetail(event.target,id,image);
        })
        
      }else{
        this.transitionToProjectDetail(event.target,id,image);
      }
      
      
    },
  }
}
</script>

<style lang="scss">
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url(./assets/css/reset.css);

@import '@/scss/_global.scss';

#sectionTransitionContainer {
  position:fixed;
  padding:0 $main-padding 0 $main-padding;
  top:100px;
  left:0px;
  z-index: 9;
  width:100%;
  height:calc(100vh - $header-height);
  // background-color:blue;
  pointer-events: none;

  img, canvas{ 

    transform-origin: top left;
  }
}

//transition animation css
// .slide-enter-active,
// .slide-leave-active { 
//   transition:transform 0.75s, opacity 0.5s;
//   transition-timing-function: ease-in-out;
//   will-change: transform, opacity;
// }

// .slide-leave-to {
//   opacity:0;
//   // transform:translateY(0);
// }

// .slide-enter-from {
//   transform:translateY(100vh) translateZ(0);
// }

// .slide-enter-to {
//   transform:translateY(0) translateZ(0);
// }

</style>
