<template>
   <div ref="header" class="header">
    <div class="header__bg"></div>
    <div v-if="isLoading" class="header__loading">
        <div class="header__loading-msg" v-letter-wrap="{text:'LOADING', classPrefix:'ld', type:'span'}"></div>
        <div class="header__loading-bg"></div>
    </div>
      <div class="header__wrapper row">
        <div @click.prevent="handleLogoClick" class="header__logo column">
          <div class="header__site-name">E8 INTERACTIVE</div>
          <div class="header__site-name-user">JAMES SAFECHUCK PORTFOLIO</div>
        </div>
      <div ref="navWrapper" class="header__nav-wrapper column">
          <!-- <div ref="hamburgerItem" class="header__hamburger-lines" @click="handleHamburgerClick">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
          </div>  -->
        <nav>
          <!-- <router-link to="/">HOME</router-link> -->
          <router-link to="/" @mouseenter="handleNavItemMouseEnter"
          @mouseleave="handleNavItemMouseLeave">
            <span class="nav__item top" v-letter-wrap="{text:'WORK', classPrefix:'nav-word', type:'span'}"></span>
            <span class="nav__item base" v-letter-wrap="{text:'WORK', classPrefix:'nav-word', type:'span'}"></span>
          </router-link>
          <router-link to="/about" @mouseenter="handleNavItemMouseEnter"
          @mouseleave="handleNavItemMouseLeave">
            <span class="nav__item top" v-letter-wrap="{text:'ABOUT', classPrefix:'nav-word', type:'span'}"></span>
            <span class="nav__item base" v-letter-wrap="{text:'ABOUT', classPrefix:'nav-word', type:'span'}"></span>
          </router-link>
        </nav>
      <social-buttons></social-buttons>
      </div>
    </div>
  </div>
</template>

<script>
// import emitter from "@/utils/Emitter";
// import {VIEW_TRANSITION_IN_COMPLETE,VIEW_TRANSITION_START} from "@/utils/consts";
import SocialButtons from "@/components/SocialButtons.vue";


    export default{
        name:"HeaderView",
        // emits:["hamburgerClick"],
        components:{
          SocialButtons,
        },
        setup() {
        },
        data(){
          return{
            isLoading:false,
          }
        },
        mounted:function(){
          // emmiter.on()
          // console.log("header mounted");
          // emitter.on(VIEW_TRANSITION_START,this.showLoading);
          // emitter.on(VIEW_TRANSITION_IN_COMPLETE,this.hideLoading);
        },
        unmounted:function(){
          // console.log("header unmounted");
          // emitter.off(VIEW_TRANSITION_START,this.showLoading);
          // emitter.off(VIEW_TRANSITION_IN_COMPLETE,this.hideLoading);

        },
        methods:{
          handleNavItemMouseEnter:function(event){
            // console.log("handleNavItemMouseOver()")
            // console.log(event.target);
            event.target.classList.add("focus");
            event.target.classList.remove("unfocus");
            
          },
          handleNavItemMouseLeave:function(event){
            // console.log("handleNavItemMouseLeave()")

             event.target.classList.remove("focus");
             event.target.classList.add("unfocus");
          },
          handleHamburgerClick:function(event){
            this.toggleHamburger();

            // this.$emit("hamburgerClick", action);
          },
          handleLogoClick:function(event){
            this.$router.push("/");
          },
            
          toggleHamburger:function(){
            let hasClass = this.$refs.hamburgerItem.classList.contains("active");

            const action = hasClass ? "close" : "open";

            if(hasClass){
                this.hideHamburger();
            }else{
                this.showHamburger();
            }
          },
          hideHamburger:function(){
              this.$refs.hamburgerItem.classList.remove("active");
              this.$refs.navWrapper.classList.remove("hamburger-active")
              this.$refs.header.classList.remove("hamburger-active");

          },
          showHamburger:function(){
            
            this.$refs.hamburgerItem.classList.add("active");
            this.$refs.navWrapper.classList.add("hamburger-active");
            this.$refs.header.classList.add("hamburger-active")
          },
          showLoading:function(){
            this.isLoading = true;
          },
          hideLoading:function(){
            // console.log("hideLoading");
            this.isLoading = false;
          }
        },
    }

</script>

<style scoped lang="scss">
@import '@/scss/_global.scss';
//https://dev.to/drews256/ridiculously-easy-row-and-column-layouts-with-flexbox-1k01
// .header{
//   display:flex;
//   height:100px;
//   background-color: blue;
//   padding:$main-padding;
// }

$headerLoadingBackgroundColor:white;
$headerBackgroundColor:$backgroundColorBold;
$headerLoadingBackgroundTextColor:$textColorMain;

  @keyframes move-down-top {
    from {
      transform:translateY(-20px);
      opacity:0%;
    }

    25% {
      opacity:100%;
    }

    to{
      transform:translateY(0px);
      opacity:100%;
    }
  }

  @keyframes move-up-base {
    from {
      transform:translateY(20px);
      opacity: 0%;
    }

    to{
      transform:translateY(0px);
      opacity:100%;
      
    }
  }

  @keyframes move-up-top {
    from {
      transform:translateY(0px);
      opacity:100%;
    }

    // 25% {
    //   opacity:100%;
    // }

    to{
      transform:translateY(-20px);
      opacity:0%;
    }
  }

  
@keyframes waviy {
  0%,40%,100% {
    transform: translateY(0)
  }
  20% {
    transform: translateY(-10px)
  }
}



  @keyframes move-down-base {
    from {
      transform:translateY(0);
    }

    30%{
      opacity: 100%;
    }

    to{
      transform:translateY(20px);
      opacity:0%;
      
    }
  }



.row {
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  width:100%;
}

.column {
  display:flex;
  flex-direction: column;
  flex-basis:100%;
  flex:1;
}

.header{ 
  position:fixed;
  z-index: 10;
  height:$header-height;
  width:100%;
  backdrop-filter: blur(5px);
  overflow: hidden;
  // box-shadow:0px 3px 5px black;
}

.header {
  transition: height 0.5s ease-in-out;
}

.header.hamburger-active {
  height:150px;
}

.header__logo {
  // flex-flow: column;
  // pointer-events: none;
  cursor: pointer;
  white-space: nowrap;
}

.header__site-name-user {
    font-size: 1.0rem;
    margin-top: -14px;
    letter-spacing: 0.01rem;
    font-weight: 400;
    pointer-events: none;
    user-select: none;
}


.header__nav-wrapper {
  justify-content: center;
  align-items: flex-end;
  flex:0 auto;
  margin-top:5px;
}

.header__nav-wrapper.hamburger-active{
  position:relative;
  top:26px;
  transition: top 0.5s ease-in-out;
  // transition-delay: 0.25s;
}

.header__wrapper {
  // display:flex;
  // flex-flow:row;
  padding:0px $main-padding 0px $main-padding;

  // align-items: flex-end;
  align-items: center;
  height:100%;
}

.header__site-name {
  font-size:2rem;
  font-weight: bolder;
  pointer-events: none;
  user-select: none;
}

.header__bg {
  background-color:$headerBackgroundColor;
  // opacity:90%;
  width:100%;
  height:100%;
  position:absolute;
  z-index: -1;
}

.header__loading {
  position:absolute;
  top:0px;
  left:0px;
  z-index: 1;
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header__loading-bg{
  position:absolute;
  top:0px;
  left:0px;
  // z-index: 1;
  width:100%;
  height:100%;
  background:$headerLoadingBackgroundColor;
  opacity:80%;
}

.header__loading-msg{
  z-index: 1;
  font-size: 25px;
  letter-spacing: 1.5px;
  font-weight: 800;
}

.header__loading-msg:deep(span) {
  position: relative;
  display: inline-block;
  color: $headerLoadingBackgroundTextColor;
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(.1s * var(--i));
  
}

nav {
  a {
    font-weight: bold;
    font-size:1.5rem;
    color: $navItemColor;
    margin:0px 10px 0px 0px;
    text-decoration: none;
    text-underline-offset: 3px;
    clip-path:inset(0px 0px 0px 0px);

    // transition: color 500ms;
    // transition-timing-function: ease-in-out;

    &.router-link-exact-active {
      color: $navItemColorActive;
      // text-decoration: underline;
    }

    .top {
      position:absolute;
    }

    &:last-child{
      margin:0px;
    }
  }

    a {
        
    .base:deep(span) {
        display:inline-block;
    }
    
    .top:deep(span) {
       display:inline-block;
       opacity: 0%;
    }
  }

  .nav__item {
    display:inline-block;
  }
}
@media (max-width:470px) {

    .header__logo {
      // transform: scale(0.75);
      transform-origin: top left;
      position: relative;
      // top: 10px;

    }

    .header__wrapper {
      flex-direction:column;
      align-items:flex-end;
      // padding:0px 5px 0px 20px;
      height: auto;
    }

    .social-buttons-container{
      padding-left:15px;
    }
  
    .header__nav-wrapper{
      flex-direction: row;
      align-items:baseline;
      
      margin-top:0px;
  }
}

@media (max-width:290px){

    .social-buttons-container{
      display:none;
    }

}


@media (hover:hover) and (pointer:fine){

  
    a.focus {

      color:$navItemColorActive;

      .base:deep(span) {
          animation:move-down-base 0.5s;
          animation-fill-mode: forwards;
          // animation-delay: calc(.1s * var(--i));
      }
      
      .top:deep(span) {
        animation:move-down-top 0.5s;
        animation-fill-mode: forwards;
        animation-delay: calc(.05s * var(--i));
      }
      
    }

    a.unfocus {

      .base:deep(span) {
          animation:move-up-base 0.5s;
          animation-fill-mode: forwards;
          // animation-delay: calc(.1s * var(--i));
      }
      
      .top:deep(span) {
        animation:move-up-top 0.5s;
        animation-fill-mode: forwards;
        // animation-delay: calc(.1s * var(--i));
      }

    }

  }

/*
.header__nav-wrapper.hamburger-active {
  nav {
    display:flex;
    flex-direction: column;
    justify-content: left;

    a {
      text-align:right;
    }
  }
}



.header__hamburger-lines {
  display: none;
  height: 26px;
  width: 32px;
  // position: absolute;
  // top: 17px;
  // left: 20px;
  // z-index: 2;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 8px;
}

.header__hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
  pointer-events: none;
}

.header__hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
  pointer-events: none;
}

.header__hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
  pointer-events: none;
}

.header__hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
  pointer-events: none;
}

.header__hamburger-lines.active .line1 {
  transform: rotate(45deg);
}

.header__hamburger-lines.active .line2 {
  transform: scaleY(0);
}

.header__hamburger-lines.active .line3 {
  transform: rotate(-45deg);
}
*/

// @media (max-width:540px) {

//   body.mobile .header__hamburger-lines {
//     display:flex;
//   }

//   body.mobile nav {
//       display: none;
//   }
// }

@media (min-width:500px){
  nav {
    margin:0px 0px 0px 10px;
  }
}

</style>