

// take a string and split each letter into span element so we can apply custom css to it
export default function(app){
    app.directive("letter-wrap", (el,binding)=>{

        if(binding.value === undefined){
            return;
        }
    
        const src = binding.value.text.split("");
        let first = src.shift();
    
        const wrapType = binding.value.type;
    
        // Note:this version appends an index to the class of each item. don't think we need that
        // const html = src.reduce((previous,current,index)=>{return previous+`<${wrapType}   class=${binding.value.classPrefix+(index+1)} style="--i:${index+1}">${current}</${wrapType} >`},`<${wrapType}  class=${binding.value.classPrefix+"0"} style="--i:0">`+first+`</${wrapType} >`);
    
        const html = src.reduce((previous,current,index)=>{return previous+`<${wrapType}   class=${binding.value.classPrefix} style="--i:${index+1}">${current}</${wrapType} >`},`<${wrapType}  class=${binding.value.classPrefix} style="--i:0">`+first+`</${wrapType} >`);
    
        // console.log(html);
    
        el.innerHTML = html;
    
        // console.log(el,binding);
    })
}

