<template>
    <div class="social-buttons-container">
        <SocialBug class="instagram" :url="instagramURL">
            <svg width="17" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" fill="#9297A0"><path d="M8.45 1.736c2.136 0 2.39.008 3.233.047.78.035 1.204.165 1.486.275.348.128.662.333.92.598.266.259.471.573.6.921.109.282.239.706.274 1.486.039.844.047 1.096.047 3.233 0 2.136-.008 2.389-.047 3.232-.035.78-.165 1.204-.275 1.486a2.652 2.652 0 0 1-1.518 1.518c-.282.11-.706.24-1.486.276-.843.038-1.096.046-3.232.046-2.137 0-2.39-.008-3.233-.046-.78-.036-1.204-.166-1.486-.276a2.477 2.477 0 0 1-.92-.598 2.476 2.476 0 0 1-.6-.921c-.109-.282-.24-.706-.275-1.486-.038-.844-.046-1.096-.046-3.233 0-2.136.008-2.389.046-3.232.036-.78.166-1.204.276-1.486.128-.348.332-.663.598-.92.259-.267.573-.471.921-.6.282-.109.706-.24 1.486-.275.844-.038 1.096-.046 3.233-.046H8.45zM8.452.293c-2.173 0-2.446.01-3.299.048-.853.04-1.434.176-1.942.373a3.928 3.928 0 0 0-1.418.923c-.407.4-.722.884-.923 1.418-.197.508-.333 1.09-.37 1.942-.041.85-.05 1.125-.05 3.297 0 2.173.01 2.446.049 3.299.039.853.174 1.433.371 1.941.201.534.516 1.017.923 1.417.4.407.883.722 1.418.923.508.198 1.09.333 1.941.372.852.039 1.126.048 3.299.048s2.445-.009 3.298-.048c.854-.039 1.433-.174 1.941-.372a4.088 4.088 0 0 0 2.34-2.34c.198-.508.333-1.09.372-1.942.039-.851.048-1.126.048-3.298 0-2.173-.009-2.445-.048-3.299-.04-.853-.174-1.432-.372-1.94a3.921 3.921 0 0 0-.922-1.417A3.927 3.927 0 0 0 13.69.714C13.182.517 12.6.381 11.748.343 10.897.303 10.623.294 8.45.294h.002z"/><path d="M8.45 4.188a4.108 4.108 0 1 0 0 8.216 4.108 4.108 0 0 0 0-8.216zm0 6.775a2.668 2.668 0 1 1 0-5.335 2.668 2.668 0 0 1 0 5.335zm4.27-5.978a.96.96 0 1 0 0-1.92.96.96 0 0 0 0 1.92z"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(.45 .296)" d="M0 0h16v16H0z"/></clipPath></defs></svg>
            </SocialBug>
        <SocialBug class="twitter" :url="twitterURL">
            <svg width="20" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)"><path d="M17.408 4.319c.012.17.012.34.012.512 0 5.233-3.984 11.27-11.27 11.27v-.004a11.212 11.212 0 0 1-6.07-1.775 7.954 7.954 0 0 0 5.86-1.642 3.966 3.966 0 0 1-3.7-2.75 3.948 3.948 0 0 0 1.788-.069A3.961 3.961 0 0 1 .85 5.98v-.05c.55.306 1.167.477 1.798.495a3.966 3.966 0 0 1-1.226-5.288 11.242 11.242 0 0 0 8.163 4.138 3.965 3.965 0 0 1 6.75-3.613A7.95 7.95 0 0 0 18.851.7a3.976 3.976 0 0 1-1.74 2.19 7.877 7.877 0 0 0 2.274-.623 8.044 8.044 0 0 1-1.977 2.052z" fill="#9297A0"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(0 .296)" d="M0 0h19.451v16H0z"/></clipPath></defs></svg>
            </SocialBug>
        <SocialBug class="linkedin" :url="linkedInURL">
            
            <svg width="16" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y=".296" width="16" height="16" rx="4" fill="#fff"/><path d="M13.633 13.929h-2.37v-3.713c0-.885-.016-2.025-1.234-2.025-1.234 0-1.423.965-1.423 1.96v3.778H6.235V6.294H8.51v1.043h.032a2.497 2.497 0 0 1 2.245-1.233c2.403 0 2.846 1.58 2.846 3.637v4.188zM3.56 5.25a1.383 1.383 0 0 1-1.376-1.376c0-.754.62-1.376 1.376-1.376.754 0 1.375.621 1.375 1.376 0 .755-.62 1.376-1.375 1.376zm1.185 8.679H2.372V6.294h2.373v7.635zM14.815.297H1.181A1.173 1.173 0 0 0 0 1.45v13.691c.008.64.54 1.162 1.18 1.155h13.635A1.176 1.176 0 0 0 16 15.14V1.45A1.175 1.175 0 0 0 14.815.296z" fill="#9297A0"/></svg>
            </SocialBug>
    </div>
    
</template>

<script>
import SocialBug from "./socialIcons/SocialBug.vue"
// import store from "@/store/index"
export default {
    components:{
        SocialBug,
    },
   computed:{
    linkedInURL(){
         return this.$store.getters.socialLinks.linkedIn;
    },
    instagramURL(){
         return this.$store.getters.socialLinks.instagram;
    },
    twitterURL(){
         return this.$store.getters.socialLinks.twitter;
    },
   },
}
</script>

<style lang="scss" scoped>

@import "@/scss/global.scss";

$socialIconColor:white;
$socialIconColorHover:$navItemColorActive;

.social-buttons-container{
    display:flex;
    column-gap:4px;
}

.social-bug {
    cursor: pointer;

    path{
        fill:$socialIconColor;
    }

    svg:hover {
        path {
            fill:$socialIconColorHover;
        }
    }
}

.linkedin {
    rect {
        fill:gray;
    }
}



</style>