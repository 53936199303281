<template>
    <div class="work-wrapper">
        <main class="projects-container content-container">
            <!-- <TransitionGroup> -->
                <project-nav-item v-for="(item, index) in projects" 
                :key="index" 
                :item-id="item.projectId" 
                v-bind="item" 
                @onClick="handleItemClick"
                ref="projectItems"
                >
                </project-nav-item>
            <!-- </TransitionGroup> -->

         </main>
        <project-sort-view class="sort-view" ref="categoryController"></project-sort-view>
        <sort-button @click="handleSortClick" class="work-wrapper__sort-btn"></sort-button>
        <div class="content-padding"></div>
    </div><!--work-wrapper-->
</template>

<script>
import {ref} from "vue";

// import { useStore } from "vuex";
import ProjectNavItem from "./ProjectNavItem.vue";
import { onBeforeRouteLeave } from "vue-router";
import store from "@/store/index";
import ProjectSortView from "@/components/ProjectSortView.vue"
import SortButton from "@/components/SortButton.vue"
import {gsap} from "@/utils/gsap-core";
import {Flip} from "@/utils/Flip"
import ScrollerMIxin from "@/mixins/ScrollerMixin";
// import emitter from "@/utils/Emitter"
import {event} from "vue-gtag";
import {
  appendCustomPageTitle,
  updateMetaDescription,
} from "@/utils/seoUtils"

import {
    DEFAULT_META_PAGE_DESCRIPTION
} from '@/utils/consts'

export default {
  components: { 
      ProjectNavItem,
      ProjectSortView,
      SortButton,
      },
    name:"HomeMain",
    emits:["onProjectThumbClick"],
    mixins:[ScrollerMIxin],
    setup(){
        
        // const store = useStore();
        const projects = store.getters.projects;

        // we need to know if this is navigating from history or the user chose the project thumbnail
        const userDidChooseProject = ref(false);

        onBeforeRouteLeave((to)=>{
            // console.log('home onBeforeRouteLeave()');
            // console.log(to);
            // console.log("userDidChooseProject",userDidChooseProject.value);
            if(to.name === "project" && userDidChooseProject.value === true){
                // let the view know it's the first page
                store.commit("setProjectRouteDidComeFromHome",true)
            }
        });

        return{
            projects,
            userDidChooseProject,
        }
    },
    data(){
        return {
            // articles:[],
            // observer:null,
        }

    },
    methods:{
        handleItemClick:function(event,id,image){
            // console.log("article was clicked ",id);
            this.userDidChooseProject = true;
            this.$emit("onProjectThumbClick", event,id,image);
        },
        handleSortClick(active){
            // console.log("sort click ",active);
            // console.log(this.$refs.categoryController.$el);

            // console.log(this.handleSortClick.savedScrollY);
            
            if(active){
                // show the menu

                // stop body from scrolling
                setTimeout(()=>{
                    // ugly static variable cheat
                    this.handleSortClick.savedScrollY = window.scrollY;
                    document.body.classList.add("modal-open");
                },500);

                // show the view
                gsap.to(this.$refs.categoryController.$el, {
                    duration:0,
                    opacity:0,
                    display:"block",
                }).then(()=>{
                    this.$refs.categoryController.$el.classList.add("active");
                    gsap.to(this.$refs.categoryController.$el, {
                        duration:0.25,
                        opacity:1,
                        ease:"power2.inOut"
                    })
                });
            }else{

                // hide the menu and sort items

                // stop body from scrolling
                window.requestAnimationFrame(()=>{

                    document.body.classList.remove("modal-open");
                    let scrollY = this.handleSortClick.savedScrollY;
                    window.scrollTo(0, parseInt(scrollY || '0'));
                })

                //hide view and sort by selected categories
                let selectedCategories = this.$refs.categoryController.selectedCategories;

                this.$refs.categoryController.$el.classList.remove("active");

                gsap.to(this.$refs.categoryController.$el, {
                    duration:0.25,
                    opacity:0,
                    ease:"power2.inOut"
                }).then(()=>{
                    // this.$refs.categoryController.clearCategories();
                    gsap.to(this.$refs.categoryController.$el, {
                        duration:0,
                        display:"none",
                    })

                    const state = Flip.getState(".project-article");
                    // after we hide overlay sort and animate items with Flip
                    this.showProjectsByCategories(selectedCategories);
                    Flip.from(state, {
                        duration:0.5,
                        ease:"power2.inOut",
                        // fade:true,
                        // absolute:true,
                        // absoluteOnLeave:true,
                    })
                });
            }
        },
        showProjectsByCategories(categories){

            // console.log("selected categories ",categories);
            // we want to use some methods of array
                categories = Array.from(categories);
                // console.log(categories);

                if(categories.length == 0 ){
                    // console.log("show them all"); 
                    this.$refs.projectItems.forEach(element => {
                        element.$el.style.display = "block";
                    });
                }else{
                    // console.log("sort them");
                    this.$refs.projectItems.forEach(element => {
                        // console.log(element.categories);
                        let elementCategories = element.$el.getAttribute("categories");
                        // console.log(elementCategories);

                        let hasCategory = categories.some((item)=> elementCategories.includes(item));
                        if(hasCategory){
                            element.$el.style.display = "block";
                        }else{
                            element.$el.style.display = "none";
                        }
                    });

                    // record sort list for anlaytics
                    const analyticsCategories = categories.join(",");
                    // console.log(analyticsCategories);
                    event("projects_categories_sort",{
                        "sort_categories":analyticsCategories,
                    })
                }
        },

    },
    
  beforeMount(){
        appendCustomPageTitle("");
        updateMetaDescription(DEFAULT_META_PAGE_DESCRIPTION)
    },
    mounted(){
        // console.log("home mounted");
        // console.log("mouted ",this.$el.children);
        // console.log(this.$el.querySelector)

        // this.observer = new IntersectionObserver(this.handleElementInView,{

        //     root:null,/*document.querySelector("#app"),*/
        //     threshold:0.75
        // });

        // const articles = this.$el.querySelectorAll(".project-article");
        // // console.log(articles);
        // this.articles = articles;

        // this.articles.forEach((element)=>{
        //     this.observer.observe(element);
        //     // console.log(element);
        // })
        // console.log(this.articles);
        
    },
    unmounted(){
        // console.log("unmounted");
        // this.articles.forEach((element)=>{
        //     this.observer.unobserve(element);
        //     // console.log(element);
        // })
        // this.articles = [];
        // this.observer = null;
        
    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/scss/_global.scss';

.projects-container{
  display:grid;
  grid-template-columns:repeat(1,minmax(300px,1fr));
//   grid-template-rows:repeat(7,1fr);
//   grid-gap:2px;
  position:relative;
}

.work-wrapper{
    position:relative;
}

.work-wrapper__sort-btn {
    position:fixed;
    top:var(--header-height);
    right:0px;
    z-index: 2;
}

@media (min-width:$breakpoint-md) {
    .projects-container{
        grid-template-columns:repeat(2,1fr);
        // background-color:blue;
    }
}

.sort-view {
    z-index: 1;
    display:none;
}

// .project-article.in-view{
//     filter:grayscale(0%) opacity(100);
// }

// .project-article{
//     filter:grayscale(80%) opacity(0);
//     transition-property: filter;
//     transition-duration:0.75s;
//     transition-timing-function:ease-in-out ;
// }

</style>