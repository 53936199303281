function loadImage(src){
    return new Promise(function(resolve,reject){
        let image = new Image();
        image.addEventListener("load",function(){
            resolve(image);
        },false);
        image.addEventListener("error",function(){
            reject(new Error("Cannot load image"));
        },false);

        image.src = src;
    })
}

export {loadImage};