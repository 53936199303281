<template>
    <div class="category-select-btn font-weight-large"
    @click="handleClick"
    :class="{active:active}"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    props:{
        categoryId:String,
    },
    emits:['click'],
    setup() {
        
    },
    data(){
        return {
            active:false,
        }
    },
    methods:{
        getActive(){
            return this.active;
        },
        setActive(value){
            this.active = value;
        },
        handleClick(event){
            // console.log("category btn handle Click");
            this.active = !this.active;
            this.$emit("click", this,this.categoryId, this.active);
            // console.log(this.active);
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_global.scss";

$buttonColor:$textColorMain;
$backgroundHoverColor:$backgroundColorDark;
$buttonColorHover:$backgroundColorLight;

.category-select-btn {
    // background:white;
    color:$buttonColor;
    padding:10px;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    margin-bottom: 2px;
    transition:background 0.15s, color 0.15s;
    transition-timing-function: ease-in-out;
}

.category-select-btn.active {
    background:$backgroundHoverColor;
    color:$buttonColorHover;
}

@media (hover:hover){
    .category-select-btn:hover{
        background:$backgroundHoverColor;
        color:$buttonColorHover;
    }
}

</style>