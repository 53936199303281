import {
    updatePropOfElement
} from './functionalUtils'


const updateTitle = updatePropOfElement('title','innerHTML');
const updateMetaDescription = updatePropOfElement("meta[name=description]",'content');

const appendCustomPageTitle = (val) =>{
    updateTitle(process.env.VUE_APP_SITE_TITLE_BASE+val);
}


// updateTitle('wtf2')
// updateMetaDescription("here is some new content")


export {
    updateTitle,
    updateMetaDescription,
    appendCustomPageTitle,
}