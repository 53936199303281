<template>
    <a class="social-bug" :href="url" target="_blank">
        <slot></slot>
    </a>
</template>

<script>
export default {
   name: 'SocialBug',
   props:["url"],
}
</script>

<style lang="scss">

</style>