import {
    curry,
    compose,
} from 'ramda';

// const debug = curry((msg,x)=>{
//     console.log(msg, x);
//     return x;
// })

const getElement = curry((query) => {
    return document.querySelector(query);
});

const setProp = curry((val,prop,element)=>{
    element[prop] = val;
})

const updatePropOfElement = curry ((element,prop,val)=>{
    return compose(setProp(val,prop),getElement)(element)
})

export {
    getElement,
    setProp,
    updatePropOfElement,
}