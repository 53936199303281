import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import store from "@/store/index"
// import {SITE_DATA_NOT_LOADED} from "@/store/types";
import emitter from "@/utils/Emitter";
import {VIEW_TRANSITION_START} from "@/utils/consts";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutViewContainer.vue')
  },
  {
    path: '/project/:projectId',
    name: 'project',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectDetailView.vue'),
    props:true,
  },
]

const baseURL = process.env.NODE_ENV === 'production' ? '/portfolio' : process.env.BASE_URL;
const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory("/portfolio/"),
  history: createWebHistory(baseURL),
  routes
})

router.beforeEach(async (to,from,next)=> {
  // console.log("before each global");

  // let siteDataLoaded = store.getters.loaded;
  // console.log(siteDataLoaded);

  //note: bit of a hack here. this transition start comes from too many places
  if(to.name === "home" || to.name === "about"){
    // console.log("trigger transition start");
    emitter.emit(VIEW_TRANSITION_START);
  }

  // if(siteDataLoaded === SITE_DATA_NOT_LOADED){
  //   // console.log("lets load the data");
  //   try{
  //     let siteData = await store.dispatch('loadSiteData');
  //     // console.log(siteData);
  //     next();
      
  //   }catch(err){
  //     console.log(err);
  //   }

  //   // console.log("before each finished getting site data");
  // }else{
  //   // console.log("site data already loaded");
  //   next();
  // }

  next();


})

export default router
