<template>
    <div class="sort-btn font-weight-large"
    :class="{active:active}"
    @click="handleClick"
    >
        <span class="sort-btn__hover-mask"></span>
        <span class="sort-btn__label-text" v-if="!active">SORT</span>
        <span class="sort-btn__label-text" v-else>CLOSE</span>
    </div>
</template>

<script>
export default {
    emits:["click"],
    data(){
        return{
            active:false,
        }
    },
    methods:{
        getActive(){
            return this.active;
        },
        handleClick(){
            this.active = !this.active;
            this.$emit("click", this.active);
        },
    }
    
}
</script>

<style lang="scss" scoped>
@import '@/scss/_global.scss';


$backgroundColor:$backgroundColorDark;
$backgroundColorHover:$backgroundColorBold;
$textColor:$navItemColor;

.sort-btn {
    // font-weight: 800;
    // background-color:$backgroundColor;
    // padding:10px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.25s, color 0.25s;
    transition-timing-function: ease-in-out;
    color:$textColor;
    overflow:hidden;
}

.sort-btn::after {
    content:"";
    background-color:$backgroundColor;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;
}

.sort-btn__hover-mask {
    background-color: $backgroundColorHover;
    // content:"";
    display:block;
    height:100%;
    position: absolute;
    top:0px;
    width:120%;
    z-index: 1;
    left:-5%;
    transform:translateX(-100%) skew(-10deg);
    transition: transform .3s ease-in-out;
}

.sort-btn__label-text {
    display: block;
    position: relative;
    z-index: 1;
    // width:50%;
    margin:auto;
    padding:10px;
}

.sort-btn.active {
    // background-color: $backgroundColorHover;
    // color:$textColorActive;

        .sort-btn__hover-mask {
            transform:translateX(0);
        }
}

@media (hover:hover){
    .sort-btn:hover {
    // background-color: $backgroundColorHover;
    // color:$textColorActive;

        .sort-btn__hover-mask {
            transform:translateX(0);
        }
    }
}

</style>